import React, { useState } from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../Login/firebase"; // Make sure to import auth correctly
import Alert from "@mui/material/Alert";
import { firebaseErrors } from "../../constants";
import { CenteredLayout } from "../../components/CenteredLayout";
import { resetSchema } from "../../validationSchemas";
import { ButtonLink } from "../../components/ButtonLink";

const ResetPassword = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetSchema,
    onSubmit: (values) => {
      setError(""); // Clear previous error
      setSuccess(""); // Clear previous success message

      sendPasswordResetEmail(auth, values.email)
        .then(() => {
          setSuccess("Password reset link sent! Check your email.");
          navigate("/login"); // Redirect to login page after successful reset request
        })
        .catch((error) => {
          setError(error.message);
        });
    },
  });

  return (
    <CenteredLayout>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: 300 }}
        >
          <Typography variant="h6">Reset Your Password</Typography>

          {/* Display Success Message */}
          {success && (
            <Alert severity="success" sx={{ width: "100%" }}>
              {success}
            </Alert>
          )}

          {/* Display Error Message */}
          {error && (
            <Alert severity="error" sx={{ width: "100%" }}>
              {firebaseErrors[error]}
            </Alert>
          )}

          <TextField
            sx={{ width: "100%" }}
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <Button
            sx={{ width: "100%" }}
            color="primary"
            variant="contained"
            type="submit"
          >
            Send Reset Link
          </Button>

          <ButtonLink
            text="Never mind? Sign in."
            onClick={() => {
              navigate("/login");
            }}
          />
        </Stack>
      </form>
    </CenteredLayout>
  );
};

export default ResetPassword;
