import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveTab, setRfpName } from "../../redux/slices/tabs";
import { ref, deleteObject } from "firebase/storage";
import { storage } from "../../pages/Login/firebase";

const File = ({
  file,
  previouslyUploadedFiles,
  setPreviouslyUploadedFiles,
  setOpenSnackbar,
  setMessage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const fileName = file?.substring(0, file?.lastIndexOf(".")) || file;

  const handleCardClick = () => {
    navigate(`/analyze/${fileName}`);
    dispatch(setActiveTab("analyze"));
    dispatch(setRfpName(fileName));
  };

  const handleDelete = (fileName) => {
    const storageRef = ref(storage, `uploads/${fileName}`);
    // Delete the file
    deleteObject(storageRef)
      .then(() => {
        setOpenSnackbar(true);

        // File deleted successfully
        setMessage("File deleted successfully.");

        const updatedFiles = previouslyUploadedFiles.filter(
          (item) => item !== fileName
        );

        setPreviouslyUploadedFiles(updatedFiles);

        handleClose();
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        // setError(errors.somethingWentWrong);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tooltip title={file.length > 20 && file}>
          <CardActionArea onClick={handleCardClick}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
                  maxWidth: 180,
                }}
              >
                <DescriptionIcon fontSize="medium" />
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  variant="body2"
                  color="textPrimary"
                >
                  {file}
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Tooltip>

        <IconButton onClick={() => setOpen(true)} sx={{ m: 1, ml: "auto" }}>
          <DeleteIcon color="error" />
        </IconButton>
      </Box>
      <Dialog maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="body1" fontWeight={600}>
            Delete file
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Are you sure you want to delete {file}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button size="medium" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            size="medium"
            color="error"
            onClick={() => handleDelete(file)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

File.propTypes = {
  file: PropTypes.object.isRequired,
};

export default File;
