import { Outlet } from "react-router-dom";
import React from "react";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { Header } from "./components/Header";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import { indigo, teal, grey } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: indigo[700],
    },
    secondary: {
      main: teal[500],
    },
    background: {
      default: grey[50],
    },
    text: {
      primary: grey[900],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
      defaultProps: {
        size: "large",
        variant: "contained",
      },
    },
  },
});

function App() {
  const isUserLoggedIn = useSelector((state) => state.login.isUserLoggedIn);

  return (
    <React.StrictMode>
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        {!isUserLoggedIn ? (
          <Outlet />
        ) : (
          <>
            <Header />
            <Container maxWidth="lg">
              <Outlet />
            </Container>
          </>
        )}
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
