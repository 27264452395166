import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const Subtitle = ({ text, ...props }) => {
  return (
    <Typography variant="subtitle1" fontWeight={500} gutterBottom {...props}>
      {text}
    </Typography>
  );
};

Subtitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Subtitle;
