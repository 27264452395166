import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/slices/tabs";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

const PageNotFound = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOnClick = () => {
    dispatch(setActiveTab("upload"));
    navigate("/upload");
  };
  return (
    <Box sx={{ display: "flex", columnGap: 1, alignItems: "center" }}>
      <ErrorIcon color="error" />
      <Typography variant="body2">
        Page not found. Want to try the{" "}
        <Link component="button" onClick={handleOnClick}>
          Home
        </Link>{" "}
        page?
      </Typography>
    </Box>
  );
};

export default PageNotFound;
