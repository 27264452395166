import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Paper } from "@mui/material";
import { byteConverter, fileStatus } from "../../utils/files";
import { LinearProgressWithLabel } from "../LinearProgressWithLabel";
import { grey } from "@mui/material/colors";
import DescriptionIcon from "@mui/icons-material/Description";

const FileUploadStatus = ({ file, progress }) => {
  return (
    <Paper sx={{ mb: 2 }}>
      <Box sx={{ display: "flex", columnGap: 1 }}>
        <Box
          sx={{
            bgcolor: grey[100],
            width: "80px",
            height: "auto",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <DescriptionIcon fontSize="large" />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box>
            <Typography pt={1} variant="body2" fontWeight={600}>
              {file.path}
            </Typography>
            <Typography variant="caption">
              {byteConverter(file.size, 0, "KB")}, {fileStatus(progress)}
            </Typography>
          </Box>
          <Box>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

FileUploadStatus.propTypes = {
  file: PropTypes.object.isRequired,
};

export default FileUploadStatus;
