import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

const IconText = ({ text, icon }) => {
  return (
    <Box sx={{ display: "flex", columnGap: 1, alignItems: "center" }}>
      {icon}
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
};

IconText.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
};

export default IconText;
