import FileUpload from "@mui/icons-material/FileUpload";
import InsightsIcon from "@mui/icons-material/Insights";
import AssistantIcon from "@mui/icons-material/Assistant";

export const tabs = [
  {
    label: "Upload",
    value: "upload",
    icon: <FileUpload />,
  },
  {
    label: "Analyze",
    value: "analyze",
    disabed: true,
    icon: <InsightsIcon />,
  },
  {
    label: "Query",
    value: "query",
    disabed: true,
    icon: <AssistantIcon />,
  },
];
