import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActionArea,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  Paper,
  Typography,
} from "@mui/material";
import ChatBox from "./Chatbox";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../redux/slices/tabs";
import { Subtitle } from "../../components/Subtitle";
import { labels } from "../../constants/labels";
import axios from "axios";
import ExcelViewer from "./ExcelViewer";

const Query = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [references, setReferences] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [open, setOpen] = React.useState(false);

  const rfpName = useSelector((state) => state.tabs.rfpName);
  const rfpData = useSelector((state) => state.rfpData.rfpData);

  const isReferencesEmpty = references?.length === 0;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("query"));
  }, [dispatch]);

  const handleSuggestionClick = (suggestion) => {
    handleSend(suggestion);
  };

  const handleSend = async (suggestion) => {
    if (input.trim() || suggestion) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: suggestion ? suggestion : input,
          fromSender: true,
          loading: false,
        },
      ]);
      setInput("");

      try {
        setIsLoading(true);

        const response = await axios.post(
          `${process.env.REACT_APP_QUERY_API}/query`,
          {
            query: suggestion ? suggestion : input,
            rfp_name: rfpName,
          }
        );

        const apiResponse = {
          fromSender: false,
          text: response?.data?.response[0],
        };
        setReferences(response?.data?.response[1]);
        setFileUrl(response?.data?.response[2]);
        setMessages((prevHistory) => [...prevHistory, apiResponse]);
      } catch (error) {
        console.error("Error sending message:", error);
        const errorMessage = {
          fromSender: false,
          text: "Something went wrong. Please try again later.",
        };
        setMessages((prevHistory) => [...prevHistory, errorMessage]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Subtitle text={labels.queryPageTitle} />
      <Typography color="textPrimary" gutterBottom variant="body2">
        {labels.queryPageDescription}
      </Typography>

      <Grid container spacing={2}>
        <Grid size={isReferencesEmpty ? 12 : 9}>
          <Paper>
            <ChatBox
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              messages={messages}
              setMessages={setMessages}
              input={input}
              setInput={setInput}
              handleSend={handleSend}
            />
          </Paper>
        </Grid>
        {!isReferencesEmpty && (
          <Grid size={3}>
            <Paper sx={{ height: 300 }}>
              <Typography
                sx={{ pl: 2, pt: 2, mb: 2 }}
                variant="body2"
                fontWeight={500}
              >
                References
              </Typography>
              <Chip
                sx={{ p: 1, ml: 2, mr: 2, height: "fit-content" }}
                onClick={handleClickOpen}
                label={
                  <Typography variant="caption" sx={{ textWrap: "initial" }}>
                    {new Set([...references])}
                  </Typography>
                }
              />
            </Paper>
          </Grid>
        )}
        <Grid size={6}>
          <Typography mb={2} variant="body2" fontWeight={500}>
            FAQs
          </Typography>
          <Grid container spacing={1}>
            {rfpData?.common_question?.map((suggestion, index) => (
              <Grid key={index} size={4}>
                <CardActionArea
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      p: 1.5,
                      display: "flex",
                      columnGap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{suggestion}</Typography>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid size={6}>
          <Typography mb={2} variant="body2" fontWeight={500}>
            AI generated questions
          </Typography>
          <Grid container spacing={1}>
            {rfpData?.questions?.map((suggestion, index) => (
              <Grid key={index} size={4}>
                <CardActionArea
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  <Card
                    variant="outlined"
                    sx={{
                      p: 1.5,
                      display: "flex",
                      columnGap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{suggestion}</Typography>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Reference</DialogTitle>
        <DialogContent>
          <ExcelViewer fileUrl={fileUrl} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Query;
