import React, { useState } from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../Login/firebase";
import Alert from "@mui/material/Alert";
import { firebaseErrors } from "../../constants";
import { CenteredLayout } from "../../components/CenteredLayout";
import { ButtonLink } from "../../components/ButtonLink";
import { emailAndPasswordSchema } from "../../validationSchemas";

const Register = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: emailAndPasswordSchema,
    onSubmit: (values) => {
      setError(""); // Clear previous error
      setSuccess(""); // Clear previous success message

      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential) => {
          // Successfully registered
          const user = userCredential.user;
          sendEmailVerification(user)
            .then(() => {
              setSuccess("Registration successful! Please verify your email.");
              navigate("/login"); // Redirect to login page after successful registration
            })
            .catch((err) =>
              setError("Verification email could not be sent: " + err.message)
            );
        })
        .catch((error) => {
          setError(error.code);
        });
    },
  });

  return (
    <CenteredLayout>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: 300 }}
        >
          <Typography variant="h6">Register for RFP Navigator</Typography>

          {/* Display Success Message */}
          {success && (
            <Alert severity="success" sx={{ width: "100%" }}>
              {success}
            </Alert>
          )}

          {/* Display Error Message */}
          {error && (
            <Alert severity="error" sx={{ width: "100%" }}>
              {firebaseErrors[error]}
            </Alert>
          )}

          <TextField
            sx={{ width: "100%" }}
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            sx={{ width: "100%" }}
            id="password"
            name="password"
            label="Password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          <Button
            sx={{ width: "100%" }}
            color="primary"
            variant="contained"
            type="submit"
          >
            Register
          </Button>
          <ButtonLink
            text="Already have an account? Sign in"
            onClick={() => {
              navigate("/login");
            }}
          />
        </Stack>
      </form>
    </CenteredLayout>
  );
};

export default Register;
