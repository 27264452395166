import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const PageTitle = ({ title, ...props }) => {
  return (
    <Typography pb={2} variant="h6" {...props}>
      {title}
    </Typography>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
};

export default PageTitle;
