import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { combineReducers } from "redux";
import { thunk } from "redux-thunk";
import loginReducer from "./slices/login";
import tabsReducer from "./slices/tabs";
import userReducer from "./slices/user";
import stepsReducer from "./slices/steps";
import rfpDataReducer from "./slices/rfpData";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  login: loginReducer,
  tabs: tabsReducer,
  user: userReducer,
  steps: stepsReducer,
  rfpData: rfpDataReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: () => [thunk],
});

export default store;
