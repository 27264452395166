import { useState, useEffect } from "react";
import { getRfpData } from "../apis/rfp";
import { useDispatch } from "react-redux";
import { setRfpData } from "../redux/slices/rfpData";
import { errors } from "../constants";

export const useData = (rfpName, key) => {
  const [rfpData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const data = await getRfpData(rfpName, key);
        setData(data);
        dispatch(setRfpData(data));
      } catch (err) {
        if (err.status === 500) {
          setError(errors.somethingWentWrong);
        } else {
          setError(err.response.data.error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchdata();
    // eslint-disable-next-line
  }, [rfpName, key]);

  return { rfpData, loading, error };
};
