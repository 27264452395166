import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD9s7mGAQIbcFdpw0QWwDZmkFvRAGsMfGM",
  authDomain: "rfp-navigator-435918.firebaseapp.com",
  projectId: "rfp-navigator-435918",
  storageBucket: "rfp-navigator-435918.appspot.com",
  messagingSenderId: "163494649877",
  appId: "1:163494649877:web:468a1b88733cc463f52e1c",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

export { auth, storage };
