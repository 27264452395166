import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { errors } from "../../constants";

const ExcelViewer = ({ fileUrl }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(fileUrl);

        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();

        const workbook = XLSX.read(arrayBuffer, { type: "array" });

        // Get the first sheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        setData(jsonData);
      } catch (error) {
        console.error("Error fetching or parsing .xlsx file:", error);
        setError(errors.documentError);
      }
    };
    fetchData();
  }, [fileUrl]);

  return (
    <div>
      {error ? (
        <Alert severity="error">{error}</Alert>
      ) : data.length > 0 ? (
        <table border="1" cellPadding="5" cellSpacing="0">
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>
                    <Typography variant="body2">{cell}</Typography>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Box>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ExcelViewer;
