import React, { useCallback, useEffect, useState } from "react";
import { ref, uploadBytesResumable } from "firebase/storage";
import Paper from "@mui/material/Paper";
import {
  Alert,
  Box,
  CircularProgress,
  Grid2 as Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { grey } from "@mui/material/colors";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Subtitle } from "../../components/Subtitle";
import { IconText } from "../../components/IconText";
import { labels } from "../../constants";
import { storage } from "../Login/firebase";
import FileUploadStatus from "../../components/FileUploadStatus/FileUploadStatus";
import { File } from "../../components/File";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/slices/tabs";
import { useFiles } from "../../hooks/useFiles";
import { firebaseErrors } from "../../constants/errors";
import { setActiveStep } from "../../redux/slices/steps";
import { hasWhiteSpace } from "../../utils/files";

const Upload = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileUploadError, setFileUploadError] = useState("");
  const [progress, setProgress] = useState(0);
  const [allFilesUploaded, setAllFilesUploaded] = useState(false);
  const [previouslyUploadedFiles, setPreviouslyUploadedFiles] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveTab("upload"));
    dispatch(setActiveStep(0));
  }, [dispatch]);

  const { filesFromStorage, loading, error } = useFiles(
    allFilesUploaded ? uploadedFiles : []
  );

  useEffect(() => {
    const files = filesFromStorage?.map(({ name }) => name);
    setPreviouslyUploadedFiles(files);
  }, [filesFromStorage]);

  const uploadFile = (file, resolve) => {
    setFileUploadError("");
    const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        setFileUploadError(error.code);
        resolve(false);
      },
      () => {
        resolve(true);
      }
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
      setAllFilesUploaded(false);

      const uploadedFiles = acceptedFiles?.map((item) => item.name);

      const containsWhiteSpaces = uploadedFiles.every((item) =>
        hasWhiteSpace(item)
      );

      const isSubset = uploadedFiles?.some((item) =>
        previouslyUploadedFiles?.includes(item)
      );

      if (isSubset) {
        // Alert user to upload a different file
        setFileUploadError("duplicate");
      } else if (containsWhiteSpaces) {
        setFileUploadError("containsSpaces");
      } else {
        // Only upload files which has unique names.
        const uploadPromises = acceptedFiles.map(
          (file) =>
            new Promise((resolve) => {
              uploadFile(file, resolve);
            })
        );
        Promise.all(uploadPromises).then(() => {
          setAllFilesUploaded(true);
        });
      }
    },
    [previouslyUploadedFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box>
      {error && <Alert severity="error">{error}</Alert>}

      <Subtitle sx={{ mt: 2 }} text={labels.uploadFiles} />
      <Typography color="textPrimary" variant="body2" gutterBottom>
        {labels.uploadFilesDescription}
      </Typography>

      <Paper sx={{ p: 4, mt: 1 }}>
        {fileUploadError && (
          <Alert sx={{ mb: 1 }} severity="error">
            {firebaseErrors[fileUploadError]}
          </Alert>
        )}
        {uploadedFiles?.length > 0 &&
          uploadedFiles?.map(
            (file, index) =>
              progress < 100 &&
              !fileUploadError && (
                <FileUploadStatus key={index} file={file} progress={progress} />
              )
          )}

        <Paper
          variant="contained"
          sx={{ display: "flex", height: 200, bgcolor: grey[100] }}
          {...getRootProps()}
        >
          <Box sx={{ m: "auto" }}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography variant="body2">
                Drag and drop your files here
              </Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <IconText
                  text={labels.uploadFilesDragAndDrop}
                  icon={<FileUploadIcon />}
                />
                <Typography mt={1} ml={1} variant="caption" color="warning">
                  Please ensure that your file name does not contain spaces
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Paper>
      {filesFromStorage?.length > 0 && (
        <Subtitle mt={2} text={labels.previouslyUploaded} />
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={2}>
          {previouslyUploadedFiles?.map((file, index) => (
            <Grid size={{ sm: 3, md: 3, lg: 3, xl: 3 }} key={index}>
              <File
                file={file}
                previouslyUploadedFiles={previouslyUploadedFiles}
                setPreviouslyUploadedFiles={setPreviouslyUploadedFiles}
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                setMessage={setMessage}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Snackbar
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
        autoHideDuration={3000}
        message={message}
      />
    </Box>
  );
};

Upload.propTypes = {};

export default Upload;
