import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import grey from "@mui/material/colors/grey";

const CenteredLayout = ({ children }) => {
  return (
    <div
      style={{
        background: grey[200],
        padding: "20px",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper sx={{ p: 8, bgcolor: grey[100] }}>{children}</Paper>
    </div>
  );
};

CenteredLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CenteredLayout;
