import { useState, useEffect } from "react";
import { getFilesFromStorage } from "../apis/files";

export const useFiles = (uploadedFiles) => {
  const [filesFromStorage, setFilesFromStorage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch files initially when the component mounts
  useEffect(() => {
    const fetchInitialFiles = async () => {
      try {
        setLoading(true); // Show loading while fetching
        const data = await getFilesFromStorage();
        setFilesFromStorage(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false); // Stop loading after fetch
      }
    };

    fetchInitialFiles();
  }, []); // Empty array ensures this only runs on mount

  // Fetch files after uploads
  useEffect(() => {
    if (uploadedFiles.length > 0) {
      const fetchFilesAfterUpload = async () => {
        try {
          setLoading(true); // Show loading during upload fetch
          const data = await getFilesFromStorage();
          setFilesFromStorage(data); // Update files after upload
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false); // Stop loading after upload fetch
        }
      };

      fetchFilesAfterUpload();
    }
  }, [uploadedFiles]);

  return { filesFromStorage, loading, error };
};
