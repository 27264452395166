import { createSlice } from "@reduxjs/toolkit";

export const rfpDataSlice = createSlice({
  name: "rfpData",
  initialState: {
    rfpData: {},
  },
  reducers: {
    setRfpData: (state, action) => {
      state.rfpData = action.payload;
    },
  },
});

export const { setRfpData } = rfpDataSlice.actions;

export default rfpDataSlice.reducer;
