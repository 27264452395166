import React from "react";
import PropTypes from "prop-types";
import { Link } from "@mui/material";

const ButtonLink = ({ text, ...props }) => {
  return (
    <Link
      variant="body2"
      style={{ textDecoration: "none" }}
      component="button"
      {...props}
    >
      {text}
    </Link>
  );
};

ButtonLink.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ButtonLink;
