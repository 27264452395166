export const firebaseErrors = {
  "auth/invalid-credential": "Invalid crendentials. Please try again.",
  "auth/email-already-in-use": "Email already in use.",
  "storage/unknown": "Something went wrong. Please try again.",
  duplicate:
    "The RFP name already exists. Please rename your file or choose a different one to upload.",
  containsSpaces:
    "File names cannot contain spaces. Please rename your file to remove any spaces and try again.",
};

export const errors = {
  somethingWentWrong: "Something went wrong. Please try again.",
  documentError:
    "Something went wrong while processing the reference document.",
};
