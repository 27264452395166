import React from "react";
import { Grid2 as Grid, Paper, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import PlaceIcon from "@mui/icons-material/Place";
import InventoryIcon from "@mui/icons-material/Inventory";
import PolicyIcon from "@mui/icons-material/Policy";
import { grey } from "@mui/material/colors";
import { Subtitle } from "../../components/Subtitle";
import { useTheme } from "@emotion/react";

const WorksheetSummary = ({ rfpData }) => {
  const theme = useTheme();
  return (
    <>
      <Subtitle text={"RFP summary"} />
      <Typography color="textPrimary" variant="body2" mb={2}>
        Your data has been thoroughly processed by RFP Navigator, covering
        essential aspects for informed decision-making.
      </Typography>
      <Paper
        variant="outlined"
        sx={{ bgcolor: grey[50], p: 1, height: "100%" }}
      >
        <Grid container textAlign="center">
          <Grid size={3}>
            <DescriptionIcon sx={{ fontSize: 30 }} />
            <Typography variant="body2" mt={1}>
              <b style={{ color: theme.palette.success.main }}>
                {rfpData?.n_worksheets}
              </b>{" "}
              Worksheet(s)
            </Typography>
          </Grid>
          <Grid size={3}>
            <PlaceIcon sx={{ fontSize: 30 }} />
            <Typography variant="body2" mt={1}>
              <b style={{ color: theme.palette.success.main }}>
                {rfpData?.n_location}
              </b>{" "}
              Location(s)
            </Typography>
          </Grid>
          <Grid size={3}>
            <InventoryIcon sx={{ fontSize: 30 }} />
            <Typography variant="body2" mt={1}>
              <b style={{ color: theme.palette.success.main }}>
                {rfpData?.n_products}
              </b>{" "}
              Product(s)
            </Typography>
          </Grid>
          <Grid size={3}>
            <PolicyIcon sx={{ fontSize: 30 }} />
            <Typography variant="body2" mt={1}>
              <b style={{ color: theme.palette.warning.main }}>
                {rfpData?.n_legal}
              </b>{" "}
              Legal clause(s)
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default WorksheetSummary;
